import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { reducers } from './state'
import { EffectsModule } from '@ngrx/effects'
import { SdkJwtEffects } from './sdk-jwt/sdk-jwt.effects'
import { ItemListEffects } from './item-list/item-list.effects'
import { ProductListEffects } from './product-list/product-list.effects'
import { OrdersEffects } from '../../../common/store/orders/orders.effects'
import { OrderSelectedEffects } from './order/order-selected/order-selected.effects'
import { StockEffects } from './stock/stock.effects'
import { ErrorEffects } from './app/error.effects'
import { GeoSearchEffects } from '../../../common/store/geo-search/geo-search.effects'
import * as fromWithdrawal from './withdrawal/withdrawal.reducer'
import { WithdrawalEffects } from './withdrawal/withdrawal.effects'
import { MinionSelectedEffects } from './minion/minion-selected/minion-selected.effects'
import { ConfigEffects } from './config/config.effects'
import { MinionEffects } from './minion/minion.effects'
import { TicketListEffects } from './ticket/ticket-list/ticket-list.effects'
import { TicketMessagesEffects } from './ticket/ticket-messages/ticket-messages.effects'
import { BonusesHistoryEffects } from './bonuses-history/bonuses-history.effects'
import { TransfersHistoryEffects } from './transfers-history/transfers-history.effects'
import { MinionListEffects } from './minion/minion-list/minion-list.effects'
import { ReviewListEffects } from './review/review-list/review-list.effects'
import { ReviewSelectedEffects } from './review/review-selected/review-selected.effects'
import { StatEffects } from './stat/stat.effects'
import { NewsEffects } from './news/news.effects'
import { EventEffects } from './events/event.effects'
import { MinionIdEffects } from './minion/minion-id/minion-id.effects'
import { MinionFamilyEffects } from './minion/minion-family/minion-family.effects'
import { MinionStocksEffects } from './minion/minion-stocks/minion-stocks.effects'
import { SystemDataEffects } from './system-data/system-data.effects'

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forRoot(reducers),
		EffectsModule.forRoot([SdkJwtEffects, ErrorEffects]),
		EffectsModule.forFeature([
			ItemListEffects,
			ProductListEffects,
			OrdersEffects,
			OrderSelectedEffects,
			StockEffects,
			GeoSearchEffects,
			WithdrawalEffects,
			MinionSelectedEffects,
			ConfigEffects,
			MinionEffects,
			TicketListEffects,
			TicketMessagesEffects,
			BonusesHistoryEffects,
			TransfersHistoryEffects,
			MinionListEffects,
			ReviewListEffects,
			ReviewSelectedEffects,
			StatEffects,
			NewsEffects,
			EventEffects,
			MinionIdEffects,
			MinionFamilyEffects,
			SystemDataEffects,
			MinionStocksEffects
		]),
		StoreModule.forFeature(fromWithdrawal.withdrawalFeatureKey, fromWithdrawal.reducer)
	]
})
export class StoresModule {}
