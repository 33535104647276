import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { FundsTransfer } from '../../../../common/models/funds-transfer'
import {
  withdrawalApproveSuccess,
  withdrawalClear,
  withdrawalLoadSuccess,
  withdrawalRejectSuccess,
} from './withdrawal.actions'
import { Navigation } from '../../../../common/models/navigation'

export const withdrawalFeatureKey = 'withdrawal'

export interface State extends EntityState<FundsTransfer> {
  navigation?: Navigation
  isLoaded?: boolean
}

const selectId = (fundsTransfer: FundsTransfer): number => fundsTransfer.id ?? 0

export const adapter = createEntityAdapter<FundsTransfer>({
  selectId,
})
export const initialState: State = adapter.getInitialState()

export const reducer = createReducer(
  initialState,
  on(withdrawalLoadSuccess, (state, { data }) =>
    adapter.setAll(data.transfers || [], { ...state, navigation: data.navigation, isLoaded: true }),
  ),
  on(withdrawalApproveSuccess, (state, { data }) =>
    adapter.updateOne(
      {
        id: data.transfer_id,
        changes: {
          transfer_status: 'FundsTransferStatusCompleted',
        },
      },
      state,
    ),
  ),
  on(withdrawalRejectSuccess, (state, { data }) =>
    adapter.updateOne(
      {
        id: data.transfer_id,
        changes: {
          transfer_status: 'FundsTransferStatusDeclined',
        },
      },
      state,
    ),
  ),
  on(withdrawalClear, () => initialState),
)
