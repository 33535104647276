import { Injectable } from '@angular/core'
import { ConfigService } from './config.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { configLoad } from './config.actions'
import { catchError, map, mergeMap, of } from 'rxjs'

@Injectable()
export class ConfigEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configLoad.start),
      mergeMap(() =>
        this.configService.load().pipe(
          map(data => configLoad.success({ data })),
          catchError(error => of(configLoad.error({ error }))),
        ),
      ),
    ),
  )

  constructor(private configService: ConfigService, private actions$: Actions) {}
}
