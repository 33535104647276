import { createAction, createActionGroup, props } from '@ngrx/store'
import { DeleteReviewsRequestInner } from 'common/models/delete-reviews-request-inner'
import { Review } from 'common/models/review'

export const reviewSelectedLoad = createActionGroup({
  source: '[Review selected] load',
  events: {
    start: props<{ reviewId: string }>(),
    success: props<{ data: Review }>(),
    error: props<{ error: string }>(),
  },
})

export const reviewSelectedClear = createAction('[Review selected] clear')

export const reviewSelectedUpdateSuccess = createAction('[Review list] update', props<{ data: Review[] }>())
export const reviewSelectedDeleteSuccess = createAction(
  '[Review list] delete',
  props<{ data: DeleteReviewsRequestInner }>(),
)

export const reviewSelectedUpdateError = createAction('[Review list] update error', props<{ error: string }>())
export const reviewSelectedDeleteError = createAction('[Review list] delete error', props<{ error: string }>())

export const allErrors = [reviewSelectedLoad.error, reviewSelectedUpdateError, reviewSelectedDeleteError]
