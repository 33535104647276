import { createReducer, on } from '@ngrx/store'
import { BonusesHistory } from '../../../../common/models/bonuses-history'
import { bonusesHistoryLoad } from './bonuses-history.actions'

export const bonusesHistoryFeatureKey = 'bonusesHistory'

export interface State {
  data?: BonusesHistory
}

export const initialState: State = {}

export const reducer = createReducer(
  initialState,
  on(bonusesHistoryLoad.success, (state, { data }) => ({
    ...state,
    data,
  })),
)
