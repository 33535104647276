import { createReducer, on } from '@ngrx/store'
import { Config } from '../../../../common/models/config'
import { configLoad } from './config.actions'

export const configFeatureKey = 'config'

export interface State {
  config?: Config
  isLoading?: boolean
  isLoaded?: boolean
}

export const initialState: State = {}

export const reducer = createReducer(
  initialState,
  on(configLoad.start, state => ({ ...state, isLoading: true })),
  on(configLoad.success, (state, { data }) => ({ ...state, config: data, isLoading: false, isLoaded: true })),
  on(configLoad.error, state => ({ ...state, isLoading: false, isLoaded: false })),
)
