import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { Navigation } from 'common/models/navigation'
import { News } from 'common/models/news'
import * as newsActions from './news.actions'

export const featureKey = 'News'

export interface State extends EntityState<News> {
  loadingNews: boolean
  loadingNewsItem: boolean
  creatingNews: boolean
  updatingNews: boolean
  deletingNews: boolean
  addingImages: boolean
  deletingImage: boolean
  currentNewsItem: News | null
  navigation: Navigation | null
  publishing: boolean
  hiding: boolean
}

const selectNewsId = (news: News) => news.id ?? -1

export const adapter: EntityAdapter<News> = createEntityAdapter<News>({ selectId: selectNewsId })

export const { selectAll } = adapter.getSelectors()

export const initialState: State = adapter.getInitialState({
  loadingNews: false,
  creatingNews: false,
  updatingNews: false,
  deletingNews: false,
  addingImages: false,
  deletingImage: false,
  currentNewsItem: null,
  loadingNewsItem: false,
  navigation: null,
  publishing: false,
  hiding: false,
})

export const reducer = createReducer(
  initialState,
  // loading news list
  on(newsActions.getNewsList.start, state => ({ ...state, loadingNews: true })),
  on(newsActions.getNewsList.success, (state, { response }) => ({
    ...adapter.setAll(response.news ?? [], state),
    navigation: response.navigation ?? null,
    loadingNews: false,
  })),
  on(newsActions.getNewsList.error, state => ({ ...state, loadingNews: false })),

  // loading news item
  on(newsActions.getNewsItem.start, state => ({ ...state, loadingNewsItem: true })),
  on(newsActions.getNewsItem.success, (state, { response }) => ({
    ...state,
    currentNewsItem: response.news,
    loadingNewsItem: false,
  })),
  on(newsActions.getNewsItem.error, state => ({ ...state, loadingNewsItem: false })),

  on(newsActions.clearCurrentNewsItem, state => ({ ...state, currentNewsItem: null })),

  // creating news item
  on(newsActions.createDraftNewsItem.start, state => ({ ...state, creatingNews: true })),
  on(newsActions.createDraftNewsItem.success, (state, { news }) => ({
    ...adapter.setAll([news, ...selectAll(state)], state),
    currentNewsItem: news,
    creatingNews: false,
  })),
  on(newsActions.createDraftNewsItem.error, state => ({ ...state, creatingNews: false })),

  // updating news item
  on(newsActions.updateNewsItem.start, state => ({ ...state, updatingNews: true })),
  on(newsActions.updateNewsItem.success, (state, { news }) => ({
    ...adapter.setOne(news, state),
    currentNewsItem: news,
    updatingNews: false,
  })),
  on(newsActions.updateNewsItem.error, state => ({ ...state, updatingNews: false })),

  //deleting news item
  on(newsActions.deleteNewsItem.start, state => ({ ...state, deletingNews: true })),
  on(newsActions.deleteNewsItem.success, (state, { id }) => ({
    ...adapter.removeOne(id, state),
    deletingNews: false,
    currentNewsItem: null,
  })),
  on(newsActions.deleteNewsItem.error, state => ({ ...state, deletingNews: false })),

  // hiding news
  on(newsActions.hideNews.start, state => ({ ...state, hiding: true })),
  on(newsActions.hideNews.success, (state, { news }) => ({
    ...adapter.setOne(news, state),
    currentNewsItem: news,
    hiding: false,
  })),
  on(newsActions.hideNews.error, state => ({ ...state, hiding: false })),

  // publishing news
  on(newsActions.publishNews.start, state => ({ ...state, publishing: true })),
  on(newsActions.publishNews.success, (state, { news }) => ({
    ...adapter.setOne(news, state),
    currentNewsItem: news,
    publishing: false,
  })),
  on(newsActions.publishNews.error, state => ({ ...state, publishing: false })),

  // adding news image
  on(newsActions.addNewsImages.start, state => ({ ...state, addingImages: true })),
  on(newsActions.addNewsImages.success, (state, { news }) => ({
    ...adapter.setOne(news, state),
    currentNewsItem: news,
    addingImages: false,
  })),
  on(newsActions.addNewsImages.error, state => ({ ...state, addingImages: false })),

  // deleting news image
  on(newsActions.deleteNewsImage.start, state => ({ ...state, deletingImage: true })),
  on(newsActions.deleteNewsImage.success, (state, { news }) => ({
    ...adapter.setOne(news, state),
    currentNewsItem: news,
    deletingImage: false,
  })),
  on(newsActions.deleteNewsImage.error, state => ({ ...state, deletingImage: false })),
)
