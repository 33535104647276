import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { bonusesHistoryLoad } from './bonuses-history.actions'
import { catchError, map, mergeMap, of } from 'rxjs'
import { BonusesHistoryService } from './bonuses-history.service'

@Injectable()
export class BonusesHistoryEffects {
  constructor(private actions$: Actions, private bonusesHistoryService: BonusesHistoryService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bonusesHistoryLoad.start),
      mergeMap(({ req }) =>
        this.bonusesHistoryService.load(req).pipe(
          map(data => bonusesHistoryLoad.success({ data })),
          catchError(error => of(bonusesHistoryLoad.error({ error }))),
        ),
      ),
    ),
  )
}
