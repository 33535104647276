import { Injectable } from '@angular/core'
import { RequestService } from 'common/services/request.service'

@Injectable({ providedIn: 'root' })
export class SystemDataService {
  constructor(private requestService: RequestService) {}

  updateSystemData() {
    return this.requestService.post(`/api/admin/update_system_data`, '')
  }
}
