import { FundsTransferListResponse } from '../../../../common/models/funds-transfer-list-response'
import { createAction, props } from '@ngrx/store'
import { ApproveWitdrawalRequest } from '../../../../common/models/approve-witdrawal-request'
import { RejectWitdrawalRequest } from '../../../../common/models/reject-witdrawal-request'

export const withdrawalLoadSuccess = createAction(
  '[Withdrawal] load success',
  props<{ data: FundsTransferListResponse }>(),
)

export const withdrawalLoadError = createAction('[Withdrawal] load error', props<{ error: string }>())

export const withdrawalApproveSuccess = createAction(
  '[Withdrawal] approve success',
  props<{ data: ApproveWitdrawalRequest }>(),
)

export const withdrawalApproveError = createAction('[Withdrawal] approve error', props<{ error: string }>())

export const withdrawalRejectSuccess = createAction(
  '[Withdrawal] reject success',
  props<{ data: RejectWitdrawalRequest }>(),
)

export const withdrawalRejectError = createAction('[Withdrawal] reject error', props<{ error: string }>())

export const withdrawalClear = createAction('[Withdrawal] clear')

export const allErrors = [withdrawalLoadError, withdrawalApproveError, withdrawalRejectError]
