import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { tap } from 'rxjs'
import { NotifyService } from '../../app/services/notify.service'
import { allErrors as itemListErrors } from '../item-list/item-list.actions'
import { allErrors as orderSelectedErrors } from '../order/order-selected/order-selected.actions'
import { allErrors as productListErrors } from '../product-list/product-list.actions'
import { allErrors as stockErrors } from '../stock/stock.actions'
import { allErrors as orderErrors } from '../../../../common/store/orders/orders.actions'
import { allErrors as withdrawalErrors } from '../withdrawal/withdrawal.actions'
import { allErrors as configErrors } from '../config/config.actions'
import { allErrors as minionErrors } from '../minion/minion.actions'
import { allErrors as ticketListErrors } from '../ticket/ticket-list/ticket-list.actions'
import { allErrors as ticketMessagesErrors } from '../ticket/ticket-messages/ticket-messages.actions'
import { allErrors as bonusesHistoryErrors } from '../bonuses-history/bonuses-history.actions'
import { allErrors as transfersHistoryErrors } from '../transfers-history/transfers-history.actions'
import { allErrors as userListErrors } from '../minion/minion-list/minion-list.actions'
import { allErrors as reviewListErrors } from '../review/review-list/review-list.actions'
import { allErrors as reviewSelectedErrors } from '../review/review-selected/review-selected.actions'
import { allErrors as statErrors } from '../stat/stat.actions'
import { allErrors as newsErrors } from '../news/news.actions'
import { allErrors as minionFamilyErrors } from '../minion/minion-family/minion-family.actions'
import { allErrors as eventErrors } from '../events/event.actions'
import { allErrors as minionStocksError } from '../minion/minion-stocks/minion-stocks.actions'

@Injectable()
export class ErrorEffects {
	constructor(private actions$: Actions, private notifyService: NotifyService) {}

	showErrors$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					...itemListErrors,
					...orderSelectedErrors,
					...productListErrors,
					...stockErrors,
					...orderErrors,
					...withdrawalErrors,
					...configErrors,
					...minionErrors,
					...ticketListErrors,
					...ticketMessagesErrors,
					...bonusesHistoryErrors,
					...transfersHistoryErrors,
					...userListErrors,
					...reviewListErrors,
					...reviewSelectedErrors,
					...statErrors,
					...newsErrors,
					...minionFamilyErrors,
					...eventErrors,
					...minionStocksError
				),
				tap(({ error }) => {
					// здесь ошибка всегда в человекочитаемом, переведенном виде.
					// переводы ошибок лежат в frontend/common/config/sdk-errors-config.ts
					this.notifyService.error(error)
				})
			),
		{ dispatch: false }
	)
}
