import { Injectable } from '@angular/core'
import { RequestService } from '../../../../common/services/request.service'
import { Observable } from 'rxjs'
import { Item } from '../../../../common/models/item'
import { ItemToAdd } from '../../../../common/models/item-to-add'
import { UpdateItemImageRequest } from '../../../../common/models/update-item-image-request'
import { DeleteItemImageRequest } from '../../../../common/models/delete-item-image-request'
import { AddItemImagesRequest } from '../../../../common/models/add-item-images-request'

@Injectable({
  providedIn: 'root',
})
export class ItemListService {
  constructor(private request: RequestService) {}

  load(): Observable<Item[]> {
    return this.request.post('/api/items/list_all', {})
  }

  add(itemToAdd: ItemToAdd): Observable<Item> {
    return this.request.post('/api/items/add', itemToAdd)
  }

  update(item: Item): Observable<Item> {
    return this.request.post('/api/items/update', item)
  }

  imageAdd(req: AddItemImagesRequest): Observable<Item> {
    return this.request.post('/api/items/images/add', req)
  }

  imageUpdate(req: UpdateItemImageRequest): Observable<Item> {
    return this.request.post('/api/items/images/update', req)
  }

  imageDelete(req: DeleteItemImageRequest): Observable<Item> {
    return this.request.post('/api/items/images/delete', req)
  }
}
