import { createReducer, on } from '@ngrx/store'
import { minionListClear, minionListLoad } from './minion-list.actions'
import { EntityState, createEntityAdapter } from '@ngrx/entity'
import { Navigation } from 'common/models/navigation'
import { MyTeamResponseMinionsInner } from 'common/models/my-team-response-minions-inner'

export const minionListFeatureKey = 'minionList'

export interface State extends EntityState<MyTeamResponseMinionsInner> {
  activePartnersCount?: number
  partnersCount?: number
  navigation?: Navigation
  isLoading?: boolean
  isLoaded?: boolean
}

const selectId = (minion: MyTeamResponseMinionsInner) => minion.minion_data.main_user_data?.visible_id ?? 0

export const adapter = createEntityAdapter<MyTeamResponseMinionsInner>({
  selectId,
})

export const initialState: State = adapter.getInitialState()

export const reducer = createReducer(
  initialState,
  on(minionListLoad.start, state => ({ ...state, isLoading: true })),
  on(minionListLoad.error, state => ({ ...state, isLoading: false, isLoaded: false })),
  on(minionListLoad.success, (state, { data }) => {
    const { minions, number_active_partners, number_partners, navigation } = data
    return adapter.setAll(minions || [], {
      ...state,
      navigation,
      activePartnersCount: number_active_partners,
      partnersCount: number_partners,
      isLoading: false,
      isLoaded: true,
    })
  }),
  on(minionListClear, () => initialState),
)
