import { LOCALE_ID, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import routes from '../pages/routes'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { NbEvaIconsModule } from '@nebular/eva-icons'
import {
  NbDatepickerModule,
  NbDialogModule,
  NbGlobalPhysicalPosition,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbToastrModule,
} from '@nebular/theme'
import { sdkImports } from './config/sdk-imports'
import { AppComponent } from './app.component'
import { defaultLocale } from '../../../common/config/locale'
import { JwtInterceptor } from 'ngx-customapp-jwt'
import { ngxOptimizedImageLoaderProvider } from '../../../common/config/ngx-optimized-image'
import { StoresModule } from '../stores/stores.module'
import { NbDateFnsDateModule } from '@nebular/date-fns'
import { ru } from 'date-fns/locale'
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { Constants } from '../../../common/utils/constants/constants'
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/translations/', '.json')
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    HttpClientModule,
    NbEvaIconsModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbToastrModule.forRoot({ position: NbGlobalPhysicalPosition.BOTTOM_RIGHT }),
    NbDialogModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDateFnsDateModule.forRoot({ parseOptions: { locale: ru } }),
    StoresModule,
    MatDialogModule,
    MatSnackBarModule,
    sdkImports,
	  TranslateModule.forRoot({
		  defaultLanguage: Constants.DEFAULT_LANG,
		  loader: {
			  provide: TranslateLoader,
			  useFactory: HttpLoaderFactory,
			  deps: [HttpClient]
		  },
	  }),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: defaultLocale,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    ngxOptimizedImageLoaderProvider,
  ],
})
export class AppModule {}
