import { createReducer, on } from '@ngrx/store'
import { revertTransfer, transfersHistoryLoad } from './transfers-history.actions'
import { EntityState, createEntityAdapter } from '@ngrx/entity'
import { FundsTransfer } from 'common/models/funds-transfer'
import { Navigation } from 'common/models/navigation'

export const transfersHistoryFeatureKey = 'transfersHistory'

export interface State extends EntityState<FundsTransfer> {
  navigation?: Navigation
  isLoading?: boolean
  isLoaded?: boolean
  revertingTransfer?: boolean
}

const selectId = (transfer: FundsTransfer) => transfer.id ?? 0

export const adapter = createEntityAdapter<FundsTransfer>({
  selectId,
})

export const initialState: State = adapter.getInitialState()

export const reducer = createReducer(
  initialState,
  on(transfersHistoryLoad.start, state => ({ ...state, isLoading: true })),
  on(transfersHistoryLoad.error, state => ({ ...state, isLoading: false, isLoaded: false })),
  on(transfersHistoryLoad.success, (state, { data }) => {
    const { transfers, navigation } = data
    return adapter.setAll(transfers || [], { ...state, navigation, isLoading: false, isLoaded: true })
  }),

  on(revertTransfer.start, state => ({ ...state, revertingTransfer: true })),
  on(revertTransfer.error, state => ({ ...state, revertingTransfer: false })),
  on(revertTransfer.success, (state, { transfer }) => {
    return { ...adapter.setOne(transfer, state), revertingTransfer: false }
  }),
)
