import { createReducer, on } from '@ngrx/store';
import { updateSystemData, updateSystemDataSuccess, updateSystemDataFailure } from './system-data.actions';

export const featureKey = 'system-data'

export interface State {
  isLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
};

export const reducer = createReducer(
  initialState,
  on(updateSystemData, (state) => ({ ...state, isLoading: true })),
  on(updateSystemDataSuccess, (state) => ({ ...state, isLoading: false })),
  on(updateSystemDataFailure, (state) => ({ ...state, isLoading: false }))
);