import { createAction, props } from '@ngrx/store'
import { ProductWithItems } from '../../../../common/models/product-with-items'
import { errorProps } from '../../../../common/utils/store/types'
import { Product } from '../../../../common/models/product'

const successProps = props<{ data: ProductWithItems[] }>()

export const productListLoadSuccess = createAction('[ProductList] load success', props<{ data: ProductWithItems[] }>())

export const productListLoadError = createAction('[ProductList] load error', errorProps)

export const productListRemove = createAction('[ProductList] remove')

export const productListAddSuccess = createAction('[ProductList] add success', successProps)

export const productListAddError = createAction('[ProductList] add error', errorProps)

// used only to handle pos. To prevent list jumps
export const productListUpdateStart = createAction('[ProductList] update start', props<{ product: Product }>())

export const productListUpdateSuccess = createAction('[ProductList] update success', successProps)

export const productListUpdateError = createAction('[ProductList] update error', errorProps)

export const productListDeleteSuccess = createAction('[ProductList] delete success', successProps)

export const productListDeleteError = createAction('[ProductList] delete error', errorProps)

export const productListCertificateAddSuccess = createAction(
  '[ProductList] certificate add success',
  props<{ data: ProductWithItems }>(),
)

export const productListCertificateAddError = createAction('[ProductList] certificate add error', errorProps)

export const productListCertificateUpdateSuccess = createAction(
  '[ProductList] certificate update success',
  props<{ data: ProductWithItems }>(),
)

export const productListCertificateUpdateError = createAction('[ProductList] certificate update error', errorProps)

export const productListCertificateDeleteSuccess = createAction(
  '[ProductList] certificate delete success',
  props<{ data: ProductWithItems }>(),
)

export const productListCertificateDeleteError = createAction('[ProductList] certificate delete error', errorProps)

export const productListSliderImageUpdateSuccess = createAction(
  '[ProductList] slider image update success',
  props<{ data: ProductWithItems }>(),
)
export const productListSliderImageUpdateError = createAction('[ProductList] slider image update error', errorProps)

export const productListSliderImageDeleteSuccess = createAction(
  '[ProductList] slider image delete success',
  props<{ data: ProductWithItems }>(),
)

export const productListSliderImageDeleteError = createAction('[ProductList] slider image delete error', errorProps)

export const allErrors = [
  productListLoadError,
  productListAddError,
  productListUpdateError,
  productListDeleteError,
  productListCertificateAddError,
  productListCertificateDeleteError,
  productListCertificateUpdateError,
  productListSliderImageUpdateError,
  productListSliderImageDeleteError,
]
