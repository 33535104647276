import { createAction, createActionGroup, props } from '@ngrx/store'
import { StockResolutionManagementRequest } from 'common/models/stock-resolution-management-request'
import { StocksResponse } from 'common/models/stocks-response'
import { SuccessResponse } from 'common/models/success-response'

export const minionStocksLoad = createActionGroup({
	source: '[Minion stocks] load',
	events: {
		start: props<{ userId: number }>(),
		success: props<{ data: StocksResponse }>(),
		error: props<{ error: string }>()
	}
})

export const minionStocksEnabledSwitcher = createActionGroup({
	source: '[Minion stocks] switcher',
	events: {
		start: props<{ req: StockResolutionManagementRequest }>(),
		success: props<{ res: SuccessResponse }>(),
		error: props<{ error: string }>()
	}
})

export const minionStocksClear = createAction('[Minion stocks] clear')

export const allErrors = [minionStocksLoad.error]
