import { StocksResponse } from '../../../../common/models/stocks-response'
import { createAction, props } from '@ngrx/store'
import { errorProps } from '../../../../common/utils/store/types'

// response on stock methods is the same for all methods
export const stockListUpdated = createAction('[Stock] list updated', props<{ data: StocksResponse }>())

export const stockLoadError = createAction('[Stock] load error', errorProps)

export const stockAddError = createAction('[Stock] add error', errorProps)

export const stockUpdateError = createAction('[Stock] update error', errorProps)

export const stockDeleteError = createAction('[Stock] delete error', errorProps)

export const allErrors = [stockLoadError, stockAddError, stockUpdateError, stockDeleteError]
