import { Injectable } from '@angular/core'
import { Actions } from '@ngrx/effects'
import { Observable } from 'rxjs'
import { FundsTransferListResponse } from '../../../../common/models/funds-transfer-list-response'
import { WithdrawalService } from './withdrawal.service'
import {
  withdrawalApproveError,
  withdrawalApproveSuccess,
  withdrawalLoadError,
  withdrawalLoadSuccess,
  withdrawalRejectError,
  withdrawalRejectSuccess,
} from './withdrawal.actions'
import { SuccessResponse } from '../../../../common/models/success-response'
import { ApproveWitdrawalRequest } from '../../../../common/models/approve-witdrawal-request'
import { RejectWitdrawalRequest } from '../../../../common/models/reject-witdrawal-request'
import { tapDispatch } from '../../../../common/utils/store/actions'
import { AppState } from '../state'
import { Store } from '@ngrx/store'
import { WithdrawalListRequest } from '../../../../common/models/withdrawal-list-request'

@Injectable()
export class WithdrawalEffects {
  load(req: WithdrawalListRequest): Observable<FundsTransferListResponse> {
    return this.withdrawalService.load(req).pipe(
      tapDispatch(
        this.store,
        data => withdrawalLoadSuccess({ data }),
        (error: string) => withdrawalLoadError({ error }),
      ),
    )
  }

  approve(req: ApproveWitdrawalRequest): Observable<SuccessResponse> {
    return this.withdrawalService.approve(req).pipe(
      tapDispatch(
        this.store,
        () => withdrawalApproveSuccess({ data: req }),
        (error: string) => withdrawalApproveError({ error }),
      ),
    )
  }

  reject(req: RejectWitdrawalRequest): Observable<SuccessResponse> {
    return this.withdrawalService.reject(req).pipe(
      tapDispatch(
        this.store,
        () => withdrawalRejectSuccess({ data: req }),
        (error: string) => withdrawalRejectError({ error }),
      ),
    )
  }

  constructor(
    private actions$: Actions,
    private withdrawalService: WithdrawalService,
    private store: Store<AppState>,
  ) {}
}
