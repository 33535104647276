import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, map, mergeMap, of } from 'rxjs'
import { MinionFromMyTeamResponse } from '../../../../../common/models/minion-from-my-team-response'
import { MinionService } from '../minion.service'
import { minionIdLoad } from './minion-id.actions'

@Injectable()
export class MinionIdEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(minionIdLoad.start),
      debounceTime(500),
      mergeMap(({ req }) =>
        this.minionService.loadOne(req).pipe(
          map((data: MinionFromMyTeamResponse) => minionIdLoad.success({ data })),
          catchError((error: string) => of(minionIdLoad.error({ error }))),
        ),
      ),
    ),
  )

  constructor(private actions$: Actions, private minionService: MinionService) {}
}
