import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { OrderSelectedService } from './order-selected.service'
import {
	orderSelectedChangeClient,
	orderSelectedDelete,
	orderSelectedLoad,
	orderSelectedRefund
} from './order-selected.actions'
import { catchError, map, mergeMap, Observable, of } from 'rxjs'
import { Order } from '../../../../../common/models/order'
import { tapDispatch } from '../../../../../common/utils/store/actions'
import { OrderIntIdRequest } from '../../../../../common/models/order-int-id-request'
import { AppState } from '../../state'
import { Store } from '@ngrx/store'
import { OrderChangeClientRequest } from '../../../../../common/models/order-change-client-request'
import { OrderVisibilityReq } from 'common/models/order-visibility-req'
import { SuccessResponse } from 'common/models/success-response'

@Injectable()
export class OrderSelectedEffects {
	constructor(
		private actions$: Actions,
		private orderSelectedService: OrderSelectedService,
		private store: Store<AppState>
	) {}

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(orderSelectedLoad.start),
			mergeMap(({ orderId }) =>
				this.orderSelectedService.load({ order_id: orderId }).pipe(
					map(order => orderSelectedLoad.success({ data: order })),
					catchError(error => of(orderSelectedLoad.error({ error })))
				)
			)
		)
	)

	refund(req: OrderIntIdRequest): Observable<Order> {
		return this.orderSelectedService.refund(req).pipe(
			tapDispatch(
				this.store,
				data => orderSelectedRefund.success({ data }),
				(error: string) => orderSelectedRefund.error({ error })
			)
		)
	}

	delete(req: OrderVisibilityReq): Observable<SuccessResponse> {
		return this.orderSelectedService.delete(req).pipe(
			tapDispatch(
				this.store,
				data => orderSelectedDelete.success({ data }),
				(error: string) => orderSelectedDelete.error({ error })
			)
		)
	}

	changeClient(req: OrderChangeClientRequest): Observable<Order> {
		return this.orderSelectedService.changeClient(req).pipe(
			tapDispatch(
				this.store,
				data => orderSelectedChangeClient.success({ data }),
				(error: string) => orderSelectedChangeClient.error({ error })
			)
		)
	}
}
