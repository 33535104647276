import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { minionFamilyLoad, minionFamilyUpdateStatus } from './minion-family.actions'
import { catchError, map, mergeMap, of } from 'rxjs'
import { MinionService } from '../minion.service'

@Injectable()
export class MinionFamilyEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(minionFamilyLoad.start),
      mergeMap(({ req }) =>
        this.minionService.loadFamily(req).pipe(
          map(data => minionFamilyLoad.success({ data })),
          catchError(error => of(minionFamilyLoad.error({ error }))),
        ),
      ),
    ),
  )

  updateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(minionFamilyUpdateStatus.start),
      mergeMap(({ req }) =>
        this.minionService.updateStatus(req).pipe(
          map(data => minionFamilyUpdateStatus.success({ data })),
          catchError(error => of(minionFamilyUpdateStatus.error({ error }))),
        ),
      ),
    ),
  )

  constructor(private actions$: Actions, private minionService: MinionService) {}
}
