import { Injectable } from '@angular/core'
import { RequestService } from '../../../../common/services/request.service'
import { Observable } from 'rxjs'
import { ProductWithItems } from '../../../../common/models/product-with-items'
import { Product } from '../../../../common/models/product'
import { DeleteRequest } from '../../../../common/models/delete-request'
import { AddProductCertificatesRequest } from '../../../../common/models/add-product-certificates-request'
import { UpdateProductCertificateRequest } from '../../../../common/models/update-product-certificate-request'
import { DeleteProductCertificateRequest } from '../../../../common/models/delete-product-certificate-request'
import { ProductToAdd } from '../../../../common/models/product-to-add'
import { UpdateProductSliderImageRequest } from '../../../../common/models/update-product-slider-image-request'
import { DeleteProductSliderImageRequest } from '../../../../common/models/delete-product-slider-image-request'

@Injectable({
  providedIn: 'root',
})
export class ProductListService {
  constructor(private request: RequestService) {}

  load(): Observable<ProductWithItems[]> {
    return this.request.post('/api/products/list_all', {})
  }

  add(req: ProductToAdd): Observable<ProductWithItems[]> {
    return this.request.post('/api/products/add', req)
  }

  update(ewaBlyat: Product): Observable<ProductWithItems[]> {
    return this.request.post('/api/products/update', ewaBlyat)
  }

  delete(req: DeleteRequest): Observable<ProductWithItems[]> {
    return this.request.post('/api/products/delete', req)
  }

  certificateAdd(req: AddProductCertificatesRequest): Observable<ProductWithItems> {
    return this.request.post('/api/products/certificates/add', req)
  }

  certificateUpdate(req: UpdateProductCertificateRequest): Observable<ProductWithItems> {
    return this.request.post('/api/products/certificates/update', req)
  }

  certificateDelete(req: DeleteProductCertificateRequest): Observable<ProductWithItems> {
    return this.request.post('/api/products/certificates//delete', req)
  }

  sliderImageUpdate(req: UpdateProductSliderImageRequest): Observable<ProductWithItems> {
    return this.request.post('/api/products/slider_image/update', req)
  }

  sliderImageDelete(req: DeleteProductSliderImageRequest): Observable<ProductWithItems> {
    return this.request.post('/api/products/slider_image/delete', req)
  }
}
