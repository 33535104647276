import { createAction, createActionGroup, props } from '@ngrx/store'
import { MyTeamForAdminRequest } from 'common/models/my-team-for-admin-request'
import { MyTeamResponse } from 'common/models/my-team-response'

export const minionListLoad = createActionGroup({
  source: '[Minion list] load',
  events: {
    start: props<{ req: MyTeamForAdminRequest }>(),
    success: props<{ data: MyTeamResponse }>(),
    error: props<{ error: string }>(),
  },
})

export const minionListClear = createAction('[Minion list] clear')

export const allErrors = [minionListLoad.error]
