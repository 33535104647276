import { createAction, props } from '@ngrx/store'
import { Item } from '../../../../common/models/item'
import { errorProps } from '../../../../common/utils/store/types'

const successProps = props<{ data: Item }>()

export const itemListLoadSuccess = createAction('[ItemList] load success', props<{ data: Item[] }>())

export const itemListLoadError = createAction('[ItemList] load error', errorProps)

export const itemListRemove = createAction('[ItemList] remove')

export const itemListAddSuccess = createAction('[ItemList] add success', successProps)

export const itemListAddError = createAction('[ItemList] add error', errorProps)

export const itemListUpdateSuccess = createAction('[ItemList] update success', successProps)

export const itemListUpdateError = createAction('[ItemList] update error', errorProps)

export const itemListImageAddSuccess = createAction('[ItemList] image add success', successProps)

export const itemListImageAddError = createAction('[ItemList] image add error', errorProps)

export const itemListImageUpdateSuccess = createAction('[ItemList] image update success', successProps)

export const itemListImageUpdateError = createAction('[ItemList] image update error', errorProps)

export const itemListImageDeleteSuccess = createAction('[ItemList] image delete success', successProps)

export const itemListImageDeleteError = createAction('[ItemList] image delete error', errorProps)

export const allErrors = [
  itemListLoadError,
  itemListAddError,
  itemListUpdateError,
  itemListImageAddError,
  itemListImageUpdateError,
  itemListImageDeleteError,
]
