import { enableProdMode } from '@angular/core'

import { environment } from './environments/environment'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import '@angular/common/locales/global/ru'
import { configureDateTime } from './app/config/time-zone'

if (environment.production) {
  enableProdMode()
}

configureDateTime()

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(error => console.error(error))
