import { Injectable } from '@angular/core'
import { AppState } from '../state'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { SuccessResponse } from '../../../../common/models/success-response'
import { AllowWitdrawalRequest } from '../../../../common/models/allow-witdrawal-request'
import { AllowWitdrawalThisMonthRequest } from '../../../../common/models/allow-witdrawal-this-month-request'
import { MinionService } from './minion.service'
import { tapDispatch } from '../../../../common/utils/store/actions'
import {
	minionGodMode,
	minionPasswordRecovery,
	minionShowInGradesRatingUpdated,
	minionShowReceivedCarInGradesRatingUpdated,
	minionUpdated,
	minionUpdateError,
	minionWithdrawalsUpdated,
	minionWithdrawalsThisMonthUpdated,
	minionWithdrawalLimitUpdated
} from './minion.actions'
import { AllUserDataResp } from '../../../../common/models/all-user-data-resp'
import { UpdateGroupOrderAccessRequest } from '../../../../common/models/update-group-order-access-request'
import { IncreaseBalanceRequest } from '../../../../common/models/increase-balance-request'
import { UpdateUserAccessRequest } from '../../../../common/models/update-user-access-request'
import { BlockUserRequest } from 'common/models/block-user-request'
import { UpdateUserInfoRequest } from '../../../../common/models/update-user-info-request'
import { AccruePresidentialPoolRequest } from '../../../../common/models/accrue-presidential-pool-request'
import { AccrueGoldProGiftRequest } from '../../../../common/models/accrue-gold-pro-gift-request'
import { ShowInGradesRatingRequest } from '../../../../common/models/show-in-grades-rating-request'
import { ShowReceivedCarInGradesRatingRequest } from 'common/models/show-received-car-in-grades-rating-request'
import { AdminPassRecoveryRequest } from 'common/models/admin-pass-recovery-request'
import { EnterGodMode } from '../../../../common/models/enter-god-mode'
import { AuthResponse } from '../../../../common/models/auth-response'
import { SwapMinionsRequest } from '../../../../common/models/swap-minions-request'
import { UserIdRequest } from '../../../../common/models/user-id-request'
import { CicTransferRequest } from 'common/models/cic-transfer-request'

@Injectable()
export class MinionEffects {
	allowWithdrawals(req: AllowWitdrawalRequest): Observable<SuccessResponse> {
		return this.minionService.allowWithdrawals(req).pipe(
			tapDispatch(
				this.store,
				() => minionWithdrawalsUpdated({ data: req }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	updateWithdrawalLimit(req: AllowWitdrawalRequest): Observable<SuccessResponse> {
		return this.minionService.updateWithdrawalLimit(req).pipe(
			tapDispatch(
				this.store,
				() => minionWithdrawalLimitUpdated({ data: req }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	allowWithdrawalsThisMonth(req: AllowWitdrawalThisMonthRequest): Observable<SuccessResponse> {
		return this.minionService.allowWithdrawalsThisMonth(req).pipe(
			tapDispatch(
				this.store,
				() => minionWithdrawalsThisMonthUpdated({ data: req }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	updateGroupOrder(req: UpdateGroupOrderAccessRequest): Observable<AllUserDataResp> {
		return this.minionService.updateGroupOrder(req).pipe(
			tapDispatch(
				this.store,
				data => minionUpdated({ data }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	updateFakeDelivery(req: UpdateUserAccessRequest): Observable<AllUserDataResp> {
		return this.minionService.updateFakeDelivery(req).pipe(
			tapDispatch(
				this.store,
				data => minionUpdated({ data }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	updateShowReceivedCarInGradesRating(req: ShowReceivedCarInGradesRatingRequest): Observable<SuccessResponse> {
		return this.minionService.updateShowReceivedCarInGradesRating(req).pipe(
			tapDispatch(
				this.store,
				() => minionShowReceivedCarInGradesRatingUpdated({ data: req }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	updateShowInGradesRating(req: ShowInGradesRatingRequest): Observable<SuccessResponse> {
		return this.minionService.updateShowInGradesRating(req).pipe(
			tapDispatch(
				this.store,
				() => minionShowInGradesRatingUpdated({ data: req }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	increaseBalance(req: IncreaseBalanceRequest): Observable<AllUserDataResp> {
		return this.minionService.increaseBalance(req).pipe(
			tapDispatch(
				this.store,
				data => minionUpdated({ data }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	deleteUser(req: UserIdRequest): Observable<AllUserDataResp> {
		return this.minionService.deleteUser(req).pipe(
			tapDispatch(
				this.store,
				data => minionUpdated({ data }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	downgradeUser(req: UserIdRequest): Observable<AllUserDataResp> {
		return this.minionService.downgradeUser(req).pipe(
			tapDispatch(
				this.store,
				data => minionUpdated({ data }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	moveToCic(req: UserIdRequest): Observable<AllUserDataResp> {
		return this.minionService.moveToCic(req).pipe(
			tapDispatch(
				this.store,
				data => minionUpdated({ data }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	blockUser(req: BlockUserRequest): Observable<AllUserDataResp> {
		return this.minionService.blockUser(req).pipe(
			tapDispatch(
				this.store,
				data => minionUpdated({ data }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	updateUserInfo(req: UpdateUserInfoRequest): Observable<AllUserDataResp> {
		return this.minionService.updateUserInfo(req).pipe(
			tapDispatch(
				this.store,
				data => minionUpdated({ data }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	accruePresidentialPool(req: AccruePresidentialPoolRequest): Observable<AllUserDataResp> {
		return this.minionService.accruePresidentialPool(req).pipe(
			tapDispatch(
				this.store,
				data => minionUpdated({ data }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	accrueGoldProGift(req: AccrueGoldProGiftRequest): Observable<AllUserDataResp> {
		return this.minionService.accrueGoldProGift(req).pipe(
			tapDispatch(
				this.store,
				data => minionUpdated({ data }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	passwordRecovery(req: AdminPassRecoveryRequest): Observable<SuccessResponse> {
		return this.minionService.passwordRecovery(req).pipe(
			tapDispatch(
				this.store,
				() => minionPasswordRecovery(),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	godMode(req: EnterGodMode): Observable<AuthResponse> {
		return this.minionService.godMode(req).pipe(
			tapDispatch(
				this.store,
				data => minionGodMode.success({ data }),
				(error: string) => minionGodMode.error({ error })
			)
		)
	}

	swap(req: SwapMinionsRequest): Observable<AllUserDataResp> {
		return this.minionService.swap(req).pipe(
			tapDispatch(
				this.store,
				data => minionUpdated({ data }),
				(error: string) => minionUpdateError({ error })
			)
		)
	}

	constructor(private store: Store<AppState>, private minionService: MinionService) {}
}
