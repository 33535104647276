import { Injectable } from '@angular/core'
import { AddEventImagesRequest } from 'common/models/add-event-images-request'
import { CreateEventRequest } from 'common/models/create-event-request'
import { DeleteEventImageRequest } from 'common/models/delete-event-image-request'
import { DeleteEventRequest } from 'common/models/delete-event-request'
import { Event } from 'common/models/event'
import { EventListResponse } from 'common/models/event-list-response'
import { UpdateEventRequest } from 'common/models/update-event-request'
import { RequestService } from 'common/services/request.service'
import { fileListToB64 } from 'common/utils/files/file-to-b64'
import { mergeMap } from 'rxjs'

export const EVENTS_PAGE_SIZE = 50

@Injectable({ providedIn: 'root' })
export class EventService {
  constructor(private requestService: RequestService) {}

  getEventList(page: number) {
    return this.requestService.post<unknown, EventListResponse>(
      '/api/events',
      {},
      { params: { page, page_size: EVENTS_PAGE_SIZE } },
    )
  }

  getEventItem(id: number) {
    return this.requestService.post<unknown, Event>(`/api/events/${id}`, {})
  }

  createDraftEventItem({ content, place, shortstory, date_begin, target_url, title }: Pick<CreateEventRequest, 'title' | 'place' | 'shortstory' | 'target_url' | 'date_begin' | 'content'>) {
    return this.requestService.post<CreateEventRequest, Event>('/api/events/create', {
      title,
      place,
      shortstory,
      target_url,
      date_begin,
      content,
      publish: false,
      images: [],
    })
  }

  updateEventItem(data: Omit<UpdateEventRequest, 'publish'>, oldEventItem: Event) {
    return this.requestService.post<UpdateEventRequest, Event>('/api/events/update', {
      ...data,
      publish: oldEventItem.published ?? false,
    })
  }

  deleteEventItem(id: number) {
    return this.requestService.post<DeleteEventRequest, void>('/api/events/delete', { event_id: id })
  }

  addEventImages(id: number, images: File[]) {
    return fileListToB64(images).pipe(
      mergeMap(files =>
        this.requestService.post<AddEventImagesRequest, Event>('/api/events/images/add', {
          event_id: id,
          images: files.map(f => ({ image: f.b64, alt: '' })),
        }),
      ),
    )
  }

  deleteEventImage(id: number, index: number) {
    return this.requestService.post<DeleteEventImageRequest, Event>('/api/events/images/delete', { index, event_id: id })
  }

  publishEvent(id: number, oldEventItem: Event) {
    return this.requestService.post<UpdateEventRequest, Event>('/api/events/update', {
      publish: true,
      place: oldEventItem.place ?? '',
      shortstory: oldEventItem.shortstory ?? '',
      target_url: oldEventItem.target_url ?? '',
      date_begin: oldEventItem.date_begin ?? '',
      content: oldEventItem.content ?? '',
      title: oldEventItem.title ?? '',
      event_id: id,
    })
  }

  hideEvent(id: number, oldEventItem: Event) {
    return this.requestService.post<UpdateEventRequest, Event>('/api/events/update', {
      publish: false,
      place: oldEventItem.place ?? '',
      shortstory: oldEventItem.shortstory ?? '',
      content: oldEventItem.content ?? '',
      target_url: oldEventItem.target_url ?? '',
      date_begin: oldEventItem.date_begin ?? '',
      title: oldEventItem.title ?? '',
      event_id: id,
    })
  }
}