import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Observable, catchError, map, mergeMap, of } from 'rxjs'
import {
  reviewSelectedDeleteError,
  reviewSelectedDeleteSuccess,
  reviewSelectedLoad,
  reviewSelectedUpdateError,
  reviewSelectedUpdateSuccess,
} from './review-selected.actions'
import { Store } from '@ngrx/store'
import { AppState } from '../../state'
import { ReviewService } from '../review.service'
import { Review } from 'common/models/review'
import { tapDispatch } from 'common/utils/store/actions'
import { DeleteReviewsRequestInner } from 'common/models/delete-reviews-request-inner'
import { SuccessResponse } from 'common/models/success-response'

@Injectable()
export class ReviewSelectedEffects {
  constructor(private actions$: Actions, private reviewService: ReviewService, private store: Store<AppState>) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reviewSelectedLoad.start),
      mergeMap(({ reviewId }) =>
        this.reviewService.loadOne(reviewId).pipe(
          map(data => reviewSelectedLoad.success({ data })),
          catchError(error => of(reviewSelectedLoad.error({ error }))),
        ),
      ),
    ),
  )

  update(data: Review): Observable<Review[]> {
    return this.reviewService.update(data).pipe(
      tapDispatch(
        this.store,
        data => reviewSelectedUpdateSuccess({ data }),
        (error: string) => reviewSelectedUpdateError({ error }),
      ),
    )
  }

  delete(data: DeleteReviewsRequestInner): Observable<SuccessResponse> {
    return this.reviewService.delete(data).pipe(
      tapDispatch(
        this.store,
        () => reviewSelectedDeleteSuccess({ data }),
        (error: string) => reviewSelectedDeleteError({ error }),
      ),
    )
  }
}
