import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Ticket } from '../../../../../common/models/ticket'
import { Navigation } from '../../../../../common/models/navigation'
import {
	ticketListClear,
	ticketListDeleteSuccess,
	ticketListLoad,
	ticketListLoadOne,
	ticketListSeizeSuccess,
	ticketListUpdateSuccess
} from './ticket-list.actions'
import { ticketMessagesDelete, ticketMessagesSendSuccess } from '../ticket-messages/ticket-messages.actions'
import { AllUserDataResp } from 'common/models/all-user-data-resp'

export const ticketListFeatureKey = 'ticketList'

export interface State extends EntityState<Ticket> {
	navigation?: Navigation
	isLoading?: boolean
	isLoaded?: boolean
	createdByInfo?: AllUserDataResp | null
}

const selectId = (ticket: Ticket) => ticket.id ?? 0

const sortByStatusAndId = (a: Ticket, b: Ticket) => {
	const statusCompare = (a.status || '').localeCompare(b.status || '')
	return statusCompare === 0 ? selectId(a) - selectId(b) : -statusCompare
}

export const adapter = createEntityAdapter<Ticket>({
	selectId
	// sortComparer: sortByStatusAndId,
})

export const initialState: State = adapter.getInitialState()

export const reducer = createReducer(
	initialState,
	on(ticketListLoad.start, ticketListLoadOne.start, state => ({ ...state, isLoading: true })),
	on(ticketListLoad.error, ticketListLoadOne.error, state => ({
		...state,
		createdByInfo: null,
		isLoading: false,
		isLoaded: false
	})),
	on(ticketListLoad.success, (state, { data }) => {
		const { tickets, navigation } = data
		return adapter.setAll(tickets || [], {
			...state,
			navigation,
			createdByInfo: null,
			isLoading: false,
			isLoaded: true
		})
	}),
	on(ticketListLoadOne.success, (state, { data }) => {
		const { ticket, created_by_info } = data

		return adapter.setAll(ticket ? [ticket] : [], {
			...state,
			isLoading: false,
			isLoaded: true,
			createdByInfo: created_by_info || null
		})
	}),
	// on(ticketListLoadOne.success, (state, { data }) =>
	// 	adapter.setAll(data.ticket ? [data.ticket] : [], { ...state, isLoading: false, isLoaded: true })
	// ),
	on(ticketListUpdateSuccess, ticketListSeizeSuccess, (state, { data }) => {
		if (!state.isLoaded) return state
		return adapter.setOne(data, state)
	}),
	on(ticketMessagesSendSuccess, ticketMessagesDelete.success, (state, { data }) => {
		if (!state.isLoaded || !data.ticket) return state
		return adapter.setOne(data.ticket, state)
	}),
	on(ticketListDeleteSuccess, (state, { data }) => {
		if (!state.isLoaded) return state
		return adapter.removeOne(data.ticket_id, state)
	}),
	on(ticketListClear, () => initialState)
)
