import { createActionGroup, props } from '@ngrx/store'
import { Order } from '../../../../../common/models/order'
import { errorProps } from '../../../../../common/utils/store/types'
import { SuccessResponse } from 'common/models/success-response'

export const orderSelectedLoad = createActionGroup({
	source: '[OrderSelected] load',
	events: {
		start: props<{ orderId: string }>(),
		success: props<{ data: Order }>(),
		error: errorProps
	}
})

export const orderSelectedDelete = createActionGroup({
	source: '[OrderSelected] delete',
	events: {
		success: props<{ data: SuccessResponse }>(),
		error: errorProps
	}
})

export const orderSelectedRefund = createActionGroup({
	source: '[OrderSelected] refund',
	events: {
		success: props<{ data: Order }>(),
		error: errorProps
	}
})

export const orderSelectedChangeClient = createActionGroup({
	source: '[OrderSelected] change client',
	events: {
		success: props<{ data: Order }>(),
		error: errorProps
	}
})

export const allErrors = [orderSelectedLoad.error, orderSelectedRefund.error]
