import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { minionStocksEnabledSwitcher, minionStocksLoad } from './minion-stocks.actions'
import { MinionService } from '../minion.service'

@Injectable()
export class MinionStocksEffects {
	constructor(private actions$: Actions, private minionService: MinionService) {}

	loadStocks$ = createEffect(() =>
		this.actions$.pipe(
			ofType(minionStocksLoad.start),
			mergeMap(action =>
				this.minionService.loadStocks(action.userId).pipe(
					map(data => minionStocksLoad.success({ data })),
					catchError(error => of(minionStocksLoad.error({ error })))
				)
			)
		)
	)

	switchStockEnabled$ = createEffect(() =>
		this.actions$.pipe(
			ofType(minionStocksEnabledSwitcher.start),
			mergeMap(action =>
				this.minionService.tooglePermissionStock(action.req).pipe(
					map(res => minionStocksEnabledSwitcher.success({ res })),
					catchError(error => of(minionStocksEnabledSwitcher.error({ error })))
				)
			)
		)
	)
}
