import { createReducer, on } from '@ngrx/store'
import { AccrualsReportResponse } from '../../../../common/models/accruals-report-response'
import { approveMarketingPeriod, statLoad } from './stat.actions'
import { Datestamp } from 'common/models/datestamp'

export const statFeatureKey = 'stat'

export interface State {
  data?: AccrualsReportResponse
  approvedMarketingPeriods?: Datestamp[]
  isLoading?: boolean
  isApproving?: boolean
}

export const initialState: State = {}

export const reducer = createReducer(
  initialState,
  on(statLoad.start, state => ({ ...state, isLoading: true })),
  on(statLoad.error, state => ({ ...state, isLoading: false })),
  on(statLoad.success, (state, { data, approvedMarketingPeriods }) => ({
    ...state,
    data,
    isLoading: false,
    approvedMarketingPeriods,
  })),

  on(approveMarketingPeriod.start, state => ({ ...state, isApproving: true })),
  on(approveMarketingPeriod.success, (state, { date }) => ({
    ...state,
    approvedMarketingPeriods: [date, ...(state.approvedMarketingPeriods ?? [])],
    isApproving: false,
  })),
  on(approveMarketingPeriod.error, state => ({ ...state, isApproving: false })),
)
