import { Injectable } from '@angular/core'
import { NbToastrService } from '@nebular/theme'

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  constructor(private toasterService: NbToastrService) {}

  success(msg: string) {
    this.toasterService.success(msg, 'Ok', { duration: 3000 })
  }

  error(msg: string) {
    this.toasterService.danger(msg, 'Error', { duration: 10_000 })
  }
}
