import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { ProductListService } from './product-list.service'
import { Store } from '@ngrx/store'
import { AppState } from '../state'
import { NotifyService } from '../../app/services/notify.service'
import { tapDispatch } from '../../../../common/utils/store/actions'
import {
  productListAddError,
  productListAddSuccess,
  productListCertificateAddError,
  productListCertificateAddSuccess,
  productListCertificateDeleteError,
  productListCertificateDeleteSuccess,
  productListDeleteError,
  productListDeleteSuccess,
  productListLoadError,
  productListLoadSuccess,
  productListSliderImageDeleteError,
  productListSliderImageDeleteSuccess,
  productListSliderImageUpdateError,
  productListSliderImageUpdateSuccess,
  productListUpdateError,
  productListUpdateStart,
  productListUpdateSuccess,
} from './product-list.actions'
import { Product } from '../../../../common/models/product'
import { DeleteRequest } from '../../../../common/models/delete-request'
import { mergeMap, Observable, tap } from 'rxjs'
import { fileListToB64, fileToB64 } from '../../../../common/utils/files/file-to-b64'
import { ProductWithItems } from '../../../../common/models/product-with-items'
import { DeleteProductCertificateRequest } from '../../../../common/models/delete-product-certificate-request'
import { ProductToAdd } from '../../../../common/models/product-to-add'
import { DeleteProductSliderImageRequest } from '../../../../common/models/delete-product-slider-image-request'

@Injectable()
export class ProductListEffects {
  constructor(
    private actions$: Actions,
    private productListService: ProductListService,
    private store: Store<AppState>,
    private notify: NotifyService,
  ) {}

  load() {
    return this.productListService.load().pipe(
      tapDispatch(
        this.store,
        data => productListLoadSuccess({ data }),
        (error: string) => productListLoadError({ error }),
      ),
    )
  }

  add(product: ProductToAdd) {
    return this.productListService.add(product).pipe(
      tapDispatch(
        this.store,
        data => productListAddSuccess({ data }),
        (error: string) => productListAddError({ error }),
      ),
    )
  }

  update(product: Product) {
    this.store.dispatch(productListUpdateStart({ product }))
    return this.productListService.update(product).pipe(
      tapDispatch(
        this.store,
        data => productListUpdateSuccess({ data }),
        (error: string) => productListUpdateError({ error }),
      ),
    )
  }

  delete(req: DeleteRequest) {
    return this.productListService.delete(req).pipe(
      tapDispatch(
        this.store,
        resp => productListDeleteSuccess({ data: resp }),
        (error: string) => productListDeleteError({ error }),
      ),
    )
  }

  certificateAdd(itemId: number, images: File[]): Observable<ProductWithItems> {
    return fileListToB64(images).pipe(
      mergeMap(b64Images =>
        this.productListService.certificateAdd({
          product_id: itemId,
          certificates: b64Images.map(image => ({
            image: image.b64,
            alt: image.name,
          })),
        }),
      ),
      tapDispatch(
        this.store,
        data => productListCertificateAddSuccess({ data }),
        (error: string) => productListCertificateAddError({ error }),
      ),
    )
  }

  certificateDelete(req: DeleteProductCertificateRequest): Observable<ProductWithItems> {
    return this.productListService.certificateDelete(req).pipe(
      tapDispatch(
        this.store,
        data => productListCertificateDeleteSuccess({ data }),
        (error: string) => productListCertificateDeleteError({ error }),
      ),
    )
  }

  sliderImageUpdate(image: File, productId: number): Observable<ProductWithItems> {
    return fileToB64(image).pipe(
      mergeMap(b64Image =>
        this.productListService.sliderImageUpdate({
          product_id: productId,
          image: b64Image.b64,
          alt: b64Image.name,
        }),
      ),
      tapDispatch(
        this.store,
        data => productListSliderImageUpdateSuccess({ data }),
        (error: string) => productListSliderImageUpdateError({ error }),
      ),
    )
  }

  sliderImageDelete(req: DeleteProductSliderImageRequest): Observable<ProductWithItems> {
    return this.productListService.sliderImageDelete(req).pipe(
      tapDispatch(
        this.store,
        data => productListSliderImageDeleteSuccess({ data }),
        (error: string) => productListSliderImageDeleteError({ error }),
      ),
    )
  }

  showError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(),
        tap(({ error }) => {
          this.notify.error(error)
        }),
      ),
    { dispatch: false },
  )
}
