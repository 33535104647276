import { createAction, createActionGroup, props } from '@ngrx/store'
import { UserVisibleIdRequest } from '../../../../../common/models/user-visible-id-request'
import { Family } from '../../../../../common/models/family'
import { errorProps } from '../../../../../common/utils/store/types'
import { ApproveOrRejectRequest } from '../../../../../common/models/approve-or-reject-request'

export const minionFamilyLoad = createActionGroup({
  source: '[MinionFamily] load agreement',
  events: {
    start: props<{ req: UserVisibleIdRequest }>(),
    success: props<{ data: Family }>(),
    error: errorProps,
  },
})

export const minionFamilyUpdateStatus = createActionGroup({
  source: '[MinionFamily] update status',
  events: {
    start: props<{ req: ApproveOrRejectRequest }>(),
    success: props<{ data: Family }>(),
    error: errorProps,
  },
})

export const minionFamilyClear = createAction('[Minion] family clear')

export const allErrors = [minionFamilyLoad.error, minionFamilyUpdateStatus.error]
