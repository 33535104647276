import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { updateSystemData, updateSystemDataSuccess, updateSystemDataFailure } from './system-data.actions';
import { SystemDataService } from './system-data.service';

@Injectable()
export class SystemDataEffects {
  constructor(
    private actions$: Actions,
    private systemDataService: SystemDataService
  ) {}

  updateSystemData$ = createEffect(() => this.actions$.pipe(
    ofType(updateSystemData),
    switchMap(() =>
      this.systemDataService.updateSystemData().pipe(
        map(() => updateSystemDataSuccess()),
        catchError(() => of(updateSystemDataFailure()))
      )
    )
  ));
}
