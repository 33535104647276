import { createActionGroup, props } from '@ngrx/store'
import { AccrualsReportRequest } from '../../../../common/models/accruals-report-request'
import { AccrualsReportResponse } from '../../../../common/models/accruals-report-response'
import { errorProps } from '../../../../common/utils/store/types'
import { Datestamp } from 'common/models/datestamp'

export const statLoad = createActionGroup({
  source: '[Stat] load',
  events: {
    start: props<{ req: AccrualsReportRequest }>(),
    success: props<{ data: AccrualsReportResponse; approvedMarketingPeriods: Datestamp[] }>(),
    error: errorProps,
  },
})

export const approveMarketingPeriod = createActionGroup({
  source: '[Stat] marketing period approve',
  events: {
    start: props<{ date: Datestamp }>(),
    success: props<{ date: Datestamp }>(),
    error: errorProps,
  },
})

export const allErrors = [statLoad.error, approveMarketingPeriod.error]
